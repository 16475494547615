import React, { type FunctionComponent, ReactElement, ReactNode, useState } from 'react';

import { Icon, InputGroup } from '@blueprintjs/core';

import { getLanguageWithRegionTag } from '../../i18n/i18n';
import {
  handleNumberOnly,
  handlePositiveAndNegativeNumberOnly,
  isNumericValue,
  removeThousandsSeparator,
  swapToDefaultSeparators,
  validateIsPositiveAndNegativeCurrency,
  validateIsPositiveCurrency,
} from '../../util/Util';

import './CurrencyInput.style.sass';

interface CurrencyInputProps {
  value?: string;
  onChange: (value: string) => void;
  label?: ReactNode;
  isEditable: boolean;
  inputProps?: any;
  isValid?: boolean;
  readOnly?: boolean;
  prefix?: '€';
  placeholder?: string;
  testId: string;
  showString?: boolean;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  allowNegativeValues?: boolean;
}

const CurrencyInput: FunctionComponent<CurrencyInputProps> = ({
  value,
  onChange,
  label,
  isEditable,
  inputProps,
  isValid,
  readOnly,
  prefix = '€',
  placeholder,
  testId,
  showString = false,
  leftElement,
  rightElement,
  allowNegativeValues = false,
}) => {
  let formattedValue = formatCurrency(toLocale(value));
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [userInputValue, setUserInputValue] = useState('');

  function formatCurrency(value?: string) {
    if (!isNumericValue(value)) {
      return value;
    }
    return value ? `${prefix}${value}` : '';
  }

  function toLocale(value?: string) {
    if (!isNumericValue(value)) {
      return value;
    }
    return value ? parseFloat(value).toLocaleString(getLanguageWithRegionTag(), { minimumFractionDigits: 2 }) : '';
  }

  function parseCurrency(input: string) {
    return input.replace(prefix, '');
  }

  function handleChange(inputValue: string) {
    const replaced = allowNegativeValues ? inputValue.replace(/[^\d.,-]/g, '') : inputValue.replace(/[^\d.,]/g, '');
    const parsed = parseCurrency(replaced);
    const swapped = swapToDefaultSeparators(parsed);
    const numericValue = removeThousandsSeparator(swapped);
    setUserInputValue(numericValue);
    onChange(numericValue);
  }

  React.Children.map(leftElement, (child, index) => {
    const leftElementItem = child as ReactElement;
    for (const selectable of leftElementItem.props.selectables) {
      if (selectable.key.value === value) {
        formattedValue = selectable.label;
        break;
      }
    }
  });

  if (!isEditable) {
    return (
      <>
        {label && <span>{label}</span>}
        <span className="currency-input-no-edit">
          {formattedValue} {inputProps?.leftIcon && <Icon icon={inputProps.leftIcon} />}
        </span>
      </>
    );
  }

  const getPlaceholder = () => `${prefix} ${placeholder ?? ``}`;
  const getIntent = (): 'none' | 'danger' => {
    if (showString) {
      return isValid ? 'none' : 'danger';
    }
    if (allowNegativeValues) {
      return validateIsPositiveAndNegativeCurrency(value ?? '') ? 'none' : 'danger';
    }
    return validateIsPositiveCurrency(value ?? '') ? 'none' : 'danger';
  };

  function onFocusAction() {
    if (!isNumericValue(value)) {
      setUserInputValue('');
    }
    setIsOnFocus(true);
  }

  function handleAllNumbers(event: React.KeyboardEvent<HTMLInputElement>) {
    if (allowNegativeValues) {
      handlePositiveAndNegativeNumberOnly(event);
    } else {
      handleNumberOnly(event);
    }
  }

  return (
    <>
      {label}
      <InputGroup
        id="currency-input"
        className="bp5-input-group currency-input"
        type="text"
        readOnly={readOnly}
        data-test-id={testId}
        intent={getIntent()}
        leftElement={leftElement}
        rightElement={rightElement}
        {...inputProps}
        placeholder={getPlaceholder()}
        value={isOnFocus ? userInputValue : formattedValue}
        onChange={event => handleChange(event.target.value)}
        onKeyDown={event => handleAllNumbers(event)}
        onFocus={event => onFocusAction()}
        onBlur={event => setIsOnFocus(false)}
      />
    </>
  );
};
export default CurrencyInput;
