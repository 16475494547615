import { nanoid } from 'nanoid';

import { RuleSetAssignmentCriteriaForReact } from './DynamicCriteriaInterfaces';
import {
  ACTION_TYPE,
  type AGGREG_FUNCTION,
  type ALLOWED_TRANSITIONS,
  type CASE_ATTRIBUTE,
  type CUSTOMER_ATTRIBUTE,
  DAY_TYPE,
  type DEFAULT_RULESET_TYPE,
  type EXECUTION_STATUS,
  INITIAL_DUNNING_LEVEL_ID,
  ITEM_ATTRIBUTE,
  type JWT_ROLES,
  type NUMERIC_ARGUMENT_FUNCTION,
  OPERATOR,
  type STATE,
} from './enums';

export enum RIGHTS {
  LIST_ALL,
  VIEW_DETAIL_RULESET,
  CREATE_RULESET,
  EDIT_RULESET,
  REPLACE_RULESET,
  IMPORT_RULESET,
  MAKE_ACTIVE_RULESET,
  DELETE_RULESET,
  SET_TO_DEFAULT_RULESET,
  ARCHIVE_RULESET,
  RETRY_ACTION,
  VIEW_DETAIL_RULE,
  CHANGE_ORDER_RULE,
  ADD_RULE,
  DELETE_RULE,
  UPDATE_RULE,
  ADJUST_CLOCK,
  SET_LIMIT_OF_DUNNING_ACTIONS_PER_DAY,
  ACCESS_ADMIN_PAGE,
  ACCESS_DUNNING_SELECTION,
  UPDATE_DUNNING_SELECTION,
  ACCESS_SETTINGS,
  ACCESS_ARCHIVE,
  SHOW_TERMINATION_CASE_CONDITION,
  EXTENDED_ACTION_INFO,
  EXECUTIONS_RESET,
}

export class SuperAdmin {
  readonly role: string = 'SuperAdmin';

  readonly rights: RIGHTS[] = [
    RIGHTS.LIST_ALL,
    RIGHTS.VIEW_DETAIL_RULESET,
    RIGHTS.CREATE_RULESET,
    RIGHTS.EDIT_RULESET,
    RIGHTS.REPLACE_RULESET,
    RIGHTS.IMPORT_RULESET,
    RIGHTS.MAKE_ACTIVE_RULESET,
    RIGHTS.DELETE_RULESET,
    RIGHTS.SET_TO_DEFAULT_RULESET,
    RIGHTS.ARCHIVE_RULESET,

    RIGHTS.VIEW_DETAIL_RULE,
    RIGHTS.CHANGE_ORDER_RULE,
    RIGHTS.ADD_RULE,
    RIGHTS.DELETE_RULE,
    RIGHTS.UPDATE_RULE,

    RIGHTS.ADJUST_CLOCK,
    RIGHTS.SET_LIMIT_OF_DUNNING_ACTIONS_PER_DAY,
    RIGHTS.RETRY_ACTION,
    RIGHTS.ACCESS_DUNNING_SELECTION,
    RIGHTS.ACCESS_ADMIN_PAGE,
    RIGHTS.ACCESS_SETTINGS,
    RIGHTS.ACCESS_ARCHIVE,
    RIGHTS.SHOW_TERMINATION_CASE_CONDITION,
    RIGHTS.UPDATE_DUNNING_SELECTION,

    RIGHTS.EXTENDED_ACTION_INFO,

    RIGHTS.EXECUTIONS_RESET,
  ];
}

export class Admin {
  readonly role: string = 'Admin';

  readonly rights: RIGHTS[] = [
    RIGHTS.LIST_ALL,
    RIGHTS.VIEW_DETAIL_RULESET,
    RIGHTS.CREATE_RULESET,
    RIGHTS.EDIT_RULESET,
    RIGHTS.REPLACE_RULESET,
    RIGHTS.IMPORT_RULESET,
    RIGHTS.MAKE_ACTIVE_RULESET,
    RIGHTS.DELETE_RULESET,
    RIGHTS.SET_TO_DEFAULT_RULESET,
    RIGHTS.ARCHIVE_RULESET,

    RIGHTS.VIEW_DETAIL_RULE,
    RIGHTS.CHANGE_ORDER_RULE,
    RIGHTS.ADD_RULE,
    RIGHTS.DELETE_RULE,
    RIGHTS.UPDATE_RULE,

    RIGHTS.ADJUST_CLOCK,
    RIGHTS.SET_LIMIT_OF_DUNNING_ACTIONS_PER_DAY,
    RIGHTS.RETRY_ACTION,
    RIGHTS.ACCESS_DUNNING_SELECTION,
    RIGHTS.ACCESS_SETTINGS,
    RIGHTS.ACCESS_ARCHIVE,
    RIGHTS.UPDATE_DUNNING_SELECTION,
  ];
}

export class Editor {
  readonly role: string = 'Editor';

  readonly rights: RIGHTS[] = [
    RIGHTS.LIST_ALL,
    RIGHTS.VIEW_DETAIL_RULESET,
    RIGHTS.CREATE_RULESET,
    RIGHTS.EDIT_RULESET,
    RIGHTS.IMPORT_RULESET,
    RIGHTS.DELETE_RULESET,

    RIGHTS.VIEW_DETAIL_RULE,
    RIGHTS.CHANGE_ORDER_RULE,
    RIGHTS.ADD_RULE,
    RIGHTS.DELETE_RULE,
    RIGHTS.UPDATE_RULE,
    RIGHTS.ACCESS_ARCHIVE,
    RIGHTS.ACCESS_DUNNING_SELECTION,
  ];
}

export class Clerk {
  readonly role: string = 'Clerk';

  readonly rights: RIGHTS[] = [
    RIGHTS.LIST_ALL,
    RIGHTS.VIEW_DETAIL_RULESET,
    RIGHTS.VIEW_DETAIL_RULE,
    RIGHTS.ACCESS_ARCHIVE,
    RIGHTS.ACCESS_DUNNING_SELECTION,
  ];
}

export type User = Admin | Editor | Clerk;

export class BasicRuleSet {
  rulesetId: string;

  name: string;

  constructor(rulesetId: string, name: string) {
    this.rulesetId = rulesetId;
    this.name = name;
  }
}

export class BasicRuleSetInfo {
  name: string;

  description: string;
}

export class AbstractRuleSet {
  rulesetId: string;

  name: string;

  description?: string;

  state: STATE;

  allowedTransitions: ALLOWED_TRANSITIONS[];

  created: string;

  updated: string;

  lastEditor: string;

  subsequentId: string;

  constructor(ruleSet: AbstractRuleSet) {
    this.rulesetId = ruleSet.rulesetId;
    this.name = ruleSet.name;
    this.lastEditor = ruleSet.lastEditor;
    this.description = ruleSet.description;
    this.state = ruleSet.state;
    this.allowedTransitions = ruleSet.allowedTransitions;
    this.created = ruleSet.created;
    this.updated = ruleSet.updated;
    this.subsequentId = ruleSet.subsequentId;
  }
}

export class RuleSet extends AbstractRuleSet {
  rules: Rule[];

  dunningLevels: DunningLevel[];

  parameters: Param[];

  subsequentRuleset: BasicRuleSet;

  constructor(ruleSet: RuleSetForReact) {
    super(ruleSet);

    this.rules = [];
    for (const rule of ruleSet.rules || []) {
      this.rules.push(new Rule(rule));
    }

    this.dunningLevels = [];
    for (const level of ruleSet.dunningLevels || []) {
      this.dunningLevels.push(new DunningLevel(level));
    }

    this.parameters = [];
    for (const param of ruleSet.parameters || []) {
      this.parameters.push(new Param(param));
    }
    if (ruleSet.subsequentRuleset != null) {
      this.subsequentRuleset = new BasicRuleSet(ruleSet.subsequentRuleset.rulesetId, ruleSet.subsequentRuleset.name);
    }
  }
}

export class RuleSetForReact extends AbstractRuleSet {
  rules: RuleForReact[];

  dunningLevels: DunningLevelForReact[];

  parameters: ParamForReact[];

  subsequentRuleset: BasicRuleSet;

  constructor(ruleSet: RuleSet) {
    super(ruleSet);

    this.rules = [];
    for (const rule of ruleSet.rules || []) {
      this.rules.push(new RuleForReact(rule));
    }

    this.dunningLevels = [];
    for (const level of ruleSet.dunningLevels || []) {
      this.dunningLevels.push(new DunningLevelForReact(level));
    }

    this.parameters = [];
    for (const param of ruleSet.parameters || []) {
      this.parameters.push(new ParamForReact(param));
    }
    if (ruleSet.subsequentRuleset != null) {
      this.subsequentRuleset = new BasicRuleSet(ruleSet.subsequentRuleset.rulesetId, ruleSet.subsequentRuleset.name);
    }
  }
}

abstract class AbstractRule {
  id: string;

  rulesetId: string;

  name: string;

  description?: string;

  created: string;

  executePerContract?: boolean;

  orderValue: number;

  updated: string;

  protected constructor(rule: Rule | RuleForReact) {
    this.id = rule.id;
    this.rulesetId = rule.rulesetId;
    this.name = rule.name;
    this.description = rule.description;
    this.created = rule.created;
    this.orderValue = rule.orderValue;
    this.updated = rule.updated;
    this.executePerContract = rule.executePerContract;
  }
}

export class Rule extends AbstractRule {
  actions: Action[];

  aggregConditions: AggregCondition[];

  caseConditions: CaseCondition[];

  customerCaseConditions: CustomerCaseCondition[];

  groupSelectors: GroupSelector[];

  constructor(rule: RuleForReact) {
    super(rule);

    this.actions = [];
    for (const action of rule.actions || []) {
      this.actions.push(new Action(action));
    }

    this.aggregConditions = [];
    for (const aggregCondition of rule.aggregConditionsInThisGroup || []) {
      this.aggregConditions.push(AggregCondition.from(aggregCondition, false));
    }

    for (const aggregCondition of rule.aggregConditionsInAllGroups || []) {
      this.aggregConditions.push(AggregCondition.from(aggregCondition, true));
    }

    this.caseConditions = [];
    for (const caseCondition of rule.caseConditions || []) {
      this.caseConditions.push(new CaseCondition(caseCondition));
    }

    this.customerCaseConditions = [];
    for (const customerCaseCondition of rule.customerCaseConditions || []) {
      this.customerCaseConditions.push(new CustomerCaseCondition(customerCaseCondition));
    }

    this.groupSelectors = [];
    for (const groupSelector of rule.groupSelectors || []) {
      this.groupSelectors.push(new GroupSelector(groupSelector));
    }
  }
}

export class RuleForReact extends AbstractRule {
  actions: ActionForReact[];

  aggregConditionsInThisGroup: AggregConditionForReact[];

  aggregConditionsInAllGroups: AggregConditionForReact[];

  caseConditions: CaseConditionForReact[];

  customerCaseConditions: CustomerCaseConditionForReact[];

  groupSelectors: GroupSelectorForReact[];

  constructor(rule: Rule) {
    super(rule);

    this.actions = [];
    for (const action of rule.actions || []) {
      this.actions.push(new ActionForReact(action));
    }

    this.aggregConditionsInThisGroup = [];
    this.aggregConditionsInAllGroups = [];

    for (const aggregCondition of rule.aggregConditions || []) {
      if (aggregCondition.customGrouping === null || aggregCondition.customGrouping === undefined) {
        this.aggregConditionsInThisGroup.push(new AggregConditionForReact(aggregCondition));
      } else {
        this.aggregConditionsInAllGroups.push(new AggregConditionForReact(aggregCondition));
      }
    }

    this.caseConditions = [];
    for (const caseCondition of rule.caseConditions || []) {
      this.caseConditions.push(new CaseConditionForReact(caseCondition));
    }

    this.customerCaseConditions = [];
    for (const customerCaseCondition of rule.customerCaseConditions || []) {
      this.customerCaseConditions.push(new CustomerCaseConditionForReact(customerCaseCondition));
    }

    this.groupSelectors = [];
    for (const groupSelector of rule.groupSelectors || []) {
      this.groupSelectors.push(new GroupSelectorForReact(groupSelector));
    }
  }
}

export class RuleAdvice {
  advice: string;

  constructor(ruleAdvice?: RuleAdvice) {
    this.advice = ruleAdvice?.advice ?? '';
  }
}

export class RuleTemplate {
  name: string;

  description: string;

  actions: ActionForReact[];

  aggregConditionsInThisGroup: AggregConditionForReact[];

  aggregConditionsInAllGroups: AggregConditionForReact[];

  caseConditions: CaseConditionForReact[];

  customerCaseConditions: CustomerCaseConditionForReact[];

  groupSelectors: GroupSelectorForReact[];

  orderValue?: number;

  executePerContract?: boolean;

  constructor(rule?: RuleForReact) {
    this.name = rule?.name ?? '';
    this.description = rule?.description ?? '';
    this.actions = rule?.actions ?? [];
    this.executePerContract = rule?.executePerContract;
    this.aggregConditionsInThisGroup = rule?.aggregConditionsInThisGroup ?? [];
    this.aggregConditionsInAllGroups = rule?.aggregConditionsInAllGroups ?? [];
    this.caseConditions = rule?.caseConditions ?? [];
    this.customerCaseConditions = rule?.customerCaseConditions ?? [];
    this.groupSelectors = rule?.groupSelectors ?? [];
    this.orderValue = rule?.orderValue;
  }
}

export class DefaultRuleSet {
  id: string;

  contractTargetType: DEFAULT_RULESET_TYPE;
}

export class Param {
  name: string;

  value: number;

  constructor(param?: Param) {
    this.name = param?.name ?? '';
    this.value = param?.value ?? 0;
  }
}

export class ParamForReact extends Param {
  reactId: string;

  isValid: boolean;

  constructor(param?: Param) {
    super(param);
    this.reactId = nanoid();
    this.isValid = true;
  }
}

export class DunningLevel {
  name: string;

  level: number;

  description: string;

  id: string;

  constructor(dunningLevel?: DunningLevel) {
    this.name = dunningLevel?.name ?? '';
    this.level = dunningLevel?.level ?? 0;
    this.description = dunningLevel?.description ?? '';
    this.id = dunningLevel?.id ?? '';
  }
}

export class DunningLevelForReact extends DunningLevel {
  reactId: string;

  isValid: boolean;

  constructor(dunningLevel?: DunningLevel) {
    super(dunningLevel);
    this.reactId = nanoid();
    this.isValid = true;
  }
}

export class Condition {
  cmp: OPERATOR;
  attrib: AttributeTypeAll;
  argument: ArgumentTypeAll;
  reactId?: string;
}

export class GroupSelector {
  attrib: ITEM_ATTRIBUTE | '';

  cmp: OPERATOR;

  argument: ArgumentTypeAll;

  constructor(groupSelector?: GroupSelector | GroupSelectorForReact) {
    this.attrib = groupSelector?.attrib ?? '';
    this.cmp = groupSelector?.cmp ?? OPERATOR.EQ;
    this.argument = groupSelector?.argument ?? new StringConst('');
  }
}

export class GroupSelectorForReact extends GroupSelector {
  reactId: string;

  isValid: boolean;

  constructor(groupSelector?: GroupSelector) {
    super(groupSelector);
    // if the rule created before Sep,2023; initial value is empty
    if (
      groupSelector?.attrib === ITEM_ATTRIBUTE.LEVEL &&
      groupSelector.argument['@type'] === RULE_ARG_TYPES.StringConst &&
      groupSelector.argument.val.length === 0
    ) {
      this.argument = new StringConst(INITIAL_DUNNING_LEVEL_ID);
    }
    this.reactId = nanoid();
    this.isValid = true;
  }
}

export class CaseCondition extends Condition {
  attrib: CASE_ATTRIBUTE | '';

  attribExtension: string;

  cmp: OPERATOR;

  argument: ArgumentTypeAll;

  constructor(caseCondition?: CaseCondition | CaseConditionForReact) {
    super();
    this.attrib = caseCondition?.attrib ?? '';
    this.attribExtension = caseCondition?.attribExtension ?? '';
    this.cmp = caseCondition?.cmp ?? OPERATOR.EQ;
    this.argument = caseCondition?.argument ?? new StringConst('');
  }
}

export class CaseConditionForReact extends CaseCondition {
  reactId: string;

  isValid: boolean;

  constructor(caseCondition?: CaseCondition) {
    super(caseCondition);
    this.reactId = nanoid();
    this.isValid = true;
  }
}

export class CustomerCaseCondition extends Condition {
  attrib: CUSTOMER_ATTRIBUTE | '';

  cmp: OPERATOR;

  argument: ArgumentTypeAll;

  attribExtension: string;

  constructor(customerCaseCondition?: CustomerCaseCondition | CustomerCaseConditionForReact) {
    super();
    this.attrib = customerCaseCondition?.attrib ?? '';
    this.attribExtension = customerCaseCondition?.attribExtension ?? '';
    this.cmp = customerCaseCondition?.cmp ?? OPERATOR.EQ;
    this.argument = customerCaseCondition?.argument ?? new StringConst('');
  }
}

export class CustomerCaseConditionForReact extends CustomerCaseCondition {
  reactId: string;

  isValid: boolean;

  constructor(caseCondition?: CustomerCaseCondition) {
    super(caseCondition);
    this.reactId = nanoid();
    this.isValid = true;
  }
}

export class AggregCondition {
  aggregFunction: AGGREG_FUNCTION | '';

  attrib: ITEM_ATTRIBUTE | '';

  cmp: OPERATOR;

  argument: ArgumentTypeAll;

  customGrouping: [] | undefined;

  constructor() {
    // TODO: This is the expected initial content according to the tests
    this.aggregFunction = '';
    this.attrib = '';
    this.cmp = OPERATOR.EQ;
    this.argument = new StringConst('');
  }

  static from(aggregCondition: AggregConditionForReact, customGrouping: boolean) {
    const result = new AggregCondition();

    result.aggregFunction = aggregCondition?.aggregFunction || '';
    result.attrib = aggregCondition?.attrib || '';
    result.cmp = aggregCondition?.cmp || OPERATOR.EQ;
    result.argument = aggregCondition?.argument;
    if (customGrouping) {
      result.customGrouping = [];
    }

    return result;
  }
}

export class AggregConditionForReact {
  aggregFunction: AGGREG_FUNCTION | '';

  attrib: ITEM_ATTRIBUTE | '';

  cmp: OPERATOR;

  argument: ArgumentTypeAll;

  isValid: boolean;

  reactId: string;

  constructor(aggregCondition?: AggregCondition) {
    this.aggregFunction = aggregCondition?.aggregFunction ?? '';
    this.attrib = aggregCondition?.attrib ?? '';
    this.cmp = aggregCondition?.cmp ?? OPERATOR.EQ;
    this.argument = aggregCondition?.argument ?? new StringConst('');
    this.reactId = nanoid();
    this.isValid = true;
  }
}

export class Action {
  '@type'?: ACTION_TYPE;

  type?: string;

  newStatus?: string;

  newStatusId?: string;

  amount?: string | number;

  settlementDays?: string | number;

  settlementDayType?: DAY_TYPE;

  autoHandover?: boolean;

  installmentPlan?: boolean;

  applyToAllReceivables?: boolean;

  constructor(action?: Action | ActionForReact) {
    this['@type'] = action?.['@type'];
    if (this['@type'] === ACTION_TYPE.CHANGE_OF_STATUS) {
      this.newStatus = action?.newStatus ?? '';
      this.newStatusId = action?.newStatusId ?? '';
      this.applyToAllReceivables = action?.applyToAllReceivables ?? false;
    } else if (
      this['@type'] === ACTION_TYPE.NOTIFICATION ||
      this['@type'] === ACTION_TYPE.DISCONNECTION_THREAT ||
      this['@type'] === ACTION_TYPE.DISCONNECTION ||
      this['@type'] === ACTION_TYPE.DISCONNECTION_ANNOUNCEMENT
    ) {
      this.type = action?.type;
      this.installmentPlan = action?.installmentPlan;
      if (this['@type'] !== ACTION_TYPE.DISCONNECTION) {
        this.settlementDays = action?.settlementDays;
        this.settlementDayType = action?.settlementDayType;
      }
    } else if (this['@type'] === ACTION_TYPE.FEE) {
      this.amount = action?.amount;
    } else if (this['@type'] === ACTION_TYPE.DEBT_COLLECTION) {
      this.autoHandover = action?.autoHandover ?? false;
    }
  }
}

export class ActionForReact extends Action {
  reactId: string;

  isValid: boolean;

  constructor(action?: Action) {
    super(action);
    this.reactId = nanoid();
    this.isValid = true;
  }
}

export class RelationalOperator {
  text: string;

  operator: OPERATOR;

  icon: any;
}

export enum RULE_ARG_TYPES {
  StringConst = 'StringConst',
  NumericConst = 'NumericConst',
  NumericParam = 'NumericParam',
  NumericComputedValue = 'NumericComputedValue',
}

export class StringConst {
  '@type': 'StringConst';

  val: string;

  constructor(argument: string) {
    this['@type'] = 'StringConst';
    this.val = argument;
  }
}

export class NumericParam {
  '@type': 'NumericParam';

  paramName: string;

  constructor(argument: string) {
    this['@type'] = 'NumericParam';
    this.paramName = argument;
  }
}

export class NumericConst {
  '@type': 'NumericConst';

  val: string;

  constructor(argument: string) {
    this['@type'] = 'NumericConst';
    this.val = argument;
  }
}

export class NumericComputedValue {
  '@type': 'NumericComputedValue';

  name: NUMERIC_ARGUMENT_FUNCTION;

  constructor(argument: NUMERIC_ARGUMENT_FUNCTION) {
    this['@type'] = 'NumericComputedValue';
    this.name = argument;
  }
}

export class Execution {
  id: string;
  timestamp: string;
  contractIds: number[];
  customerId: number;
  ruleSetName: string;
  executionState: EXECUTION_STATUS;
  outcomes: ExecutionOutcome[];
  lastModified: string;
  lastModifiedUser: string;

  constructor(execution: Execution) {
    this.id = execution.id;
    this.timestamp = execution.timestamp;
    this.contractIds = execution.contractIds;
    this.ruleSetName = execution.ruleSetName;
    this.executionState = execution.executionState;
    this.outcomes = execution.outcomes;
    this.customerId = execution.customerId;
    this.lastModified = execution.lastModified;
    this.lastModifiedUser = execution.lastModifiedUser;
  }
}

export class ExecutionForReact extends Execution {
  reactId: string;

  constructor(execution: Execution) {
    super(execution);
    this.reactId = nanoid();
  }
}

export class ExecutionOutcome {
  id: string;
  executionState: EXECUTION_STATUS;
  actionInfo: string;

  contractIds: number[];
  receivableIds: number[];
  lastModified: string;
  lastModifiedUser: string;
  ruleName: string;

  constructor(execution: ExecutionOutcome) {
    this.id = execution.id;
    this.executionState = execution.executionState;
    this.actionInfo = execution.actionInfo;
    this.contractIds = execution.contractIds;
    this.lastModified = execution.lastModified;
    this.lastModifiedUser = execution.lastModifiedUser;
    this.ruleName = execution.ruleName;
  }
}

export class RuleSetExecutionsSummary {
  summary: ExecutionsSummary;
}

export class ExecutionsSummary {
  successful: number = 0;
  failed: number = 0;
  pending: number = 0;
  retry: number = 0;
  canceled: number = 0;
}

export interface Page<T> {
  data: T[];
  pageNumber: number;
  pageSize: number;
  totalAmountOfItems: number;
}

export type ArgumentTypeAll = StringConst | NumericParam | NumericConst | NumericComputedValue;
export type AttributeTypeAll = CASE_ATTRIBUTE | CUSTOMER_ATTRIBUTE | ITEM_ATTRIBUTE | '';

export class SystemProperty {
  property: string;

  value: string;

  type: SystemPropertyType;

  requiredField: boolean;

  constructor(property: string, value: string, type: SystemPropertyType, requiredField: boolean) {
    this.property = property;
    this.value = value;
    this.type = type;
    this.requiredField = requiredField;
  }
}

export enum SystemPropertyType {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  BOOLEAN = 'BOOLEAN',
  DECIMAL = 'DECIMAL',
}

export class LevelReplacement {
  oldLevelName: string;

  oldLevelValue: number;

  newLevelName: string;

  newLevelValue: number;

  numberOfAffectedReceivables: number;
}

export class RuleSetReplacementLog {
  numberOfAffectedContracts: number;

  levelReplacements: LevelReplacement[];

  dryRun: boolean;
}

export interface DunningSelectionConfiguration {
  active: boolean;
  executionType: string;
  planedExecutionDate: string;
  paymentType: string;
  contractStates: string[];
  dunningProcedures: string[];
  minOpenAmount: number;
  maxNotifications: number;
  maxTerminations: number;
  maxThreats: number;
  maxAnnouncements: number;
  maxDisconnection: number;
  terminationProcedures: string[];
  rules: string[];
  dueDateLimitation: string;
}

export interface DunningSelectionForecastValues {
  notifications: number;
  disconnections: number;
  disconnectionsThreat: number;
  disconnectionsAnnouncement: number;
  contractTermination: number;
  total: number;
  totalOpenAmount: number;
}

export interface DunningSelectionForecast {
  forecastedDate: string;
  withoutDunningSelection: DunningSelectionForecastValues;
  withDunningSelection: DunningSelectionForecastValues;
}

export class CommunicationProfile {
  id: number;

  name: string;

  constructor(id_: number, name_: string) {
    this.id = id_;
    this.name = name_;
  }
}

export interface ExecutionFilter {
  contractId?: string;
  customerId?: string;
  executedAfter?: string;
  executedBefore?: string;
  states?: string[];
  actionTypes?: string[];
  outcomeIds?: string[];
  dunningLevels?: string[];
  ruleSetNames?: string[];
}

export interface JWT<T> {
  header: {
    typ: string;
    alg: string;
    kid: string;
  };
  payload: T;
  signature: string;
}

export interface DunningJWTPayload {
  iat: number;
  exp: number;
  iss: '';
  roles: JWT_ROLES[];
  type: string;
  username: string;
  tenant_id: number;
}

export interface ArchivedRuleSet {
  id: string;
  archived: string;
  name: string;
  archivedBy: string;
  representation: string;
}

export class RuleSetWithAssignmentCriteria extends RuleSetForReact {
  assignmentCriteria?: RuleSetAssignmentCriteriaForReact;

  constructor(ruleSet: RuleSet, assignmentCriteria?: RuleSetAssignmentCriteriaForReact) {
    super(ruleSet);
    if (assignmentCriteria) {
      this.assignmentCriteria = new RuleSetAssignmentCriteriaForReact(assignmentCriteria);
    }
  }
}
