import {
  RuleDefinitionComputedValue,
  RuleDefinitionParamValue,
  RuleDefinitionValue,
} from '../../RuleDefinition/RuleCondition/RuleInputs/RuleDefinitionBaseProps';

export const currencyValidatorProvider = (message: string) => (rdf: RuleDefinitionValue) => {
  if (rdf instanceof RuleDefinitionComputedValue || rdf instanceof RuleDefinitionParamValue) return null;
  const value = rdf.toValue();
  const currencyRegex = /^\d+(?:\.\d{1,2})?$/;
  if (!(value === undefined || Number.isNaN(value) || currencyRegex.test(value))) {
    return {
      error: true,
      message,
    };
  }
  return null;
};

export const negativeCurrencyValidatorProvider = (message: string) => (rdf: RuleDefinitionValue) => {
  if (rdf instanceof RuleDefinitionComputedValue || rdf instanceof RuleDefinitionParamValue) return null;
  const value = rdf.toValue();
  const currencyRegex = /^-?\d+(?:\.\d{1,2})?$/;
  if (!(value === undefined || Number.isNaN(value) || currencyRegex.test(value))) {
    return {
      error: true,
      message,
    };
  }
  return null;
};
