import { defaultInput, operatorSelect } from './RuleConditionHelper';
import i18n from '../../../../../i18n/i18n';
import { CASE_ATTRIBUTE, CONTRACT_TYPE, OPERATOR } from '../../../../../interfaces/enums';
import { CaseConditionForReact, DunningLevelForReact } from '../../../../../interfaces/Interfaces';
import { AppLicenseContext } from '../../../../../license/AppLicense';
import { getCommunicationProfiles } from '../../../../../services/ApiService';
import { availableComputedPropertiesFactory } from '../../../../../util/Util';
import { RuleValidators } from '../../Validation/RuleValidators';
import { RuleConditionDefinition, RuleConditionInputTypes } from '../RuleCondition/RuleConditionTypes';
import { RuleDefinitionStringValue, RuleDefinitionValue } from '../RuleCondition/RuleInputs/RuleDefinitionBaseProps';

export const caseRuleConditionDefinitionFactory: (
  dunningLevels: DunningLevelForReact[],
  license: AppLicenseContext,
) => {
  [key in CASE_ATTRIBUTE]: RuleConditionDefinition;
} = (dunningLevels, license) => {
  return {
    [CASE_ATTRIBUTE.ENERGY]: {
      inputs: [
        operatorSelect(),
        defaultInput(
          RuleConditionInputTypes.MULTI_SELECT,
          [RuleValidators.case.required],
          [
            {
              key: 'energy',
              label: i18n.t('select.electricity'),
            },
            {
              key: 'gas',
              label: i18n.t('select.gas'),
            },
          ],
        ),
      ],
    },
    [CASE_ATTRIBUTE.DISCONNECTION_STATUS]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(
          RuleConditionInputTypes.SELECT,
          [RuleValidators.case.required],
          [
            {
              key: 'done',
              label: i18n.t('select.done'),
            },
            {
              key: 'rejected',
              label: i18n.t('select.rejected'),
            },
            {
              key: 'canceled',
              label: i18n.t('select.canceled'),
            },
            {
              key: 'ended',
              label: i18n.t('select.ended'),
            },
          ],
        ),
      ],
    },
    [CASE_ATTRIBUTE.CALENDAR_DAYS_SINCE_LAST_EXECUTION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.case.required,
          RuleValidators.case.nonNegativeNumber,
        ]),
      ],
    },
    [CASE_ATTRIBUTE.WORKING_DAYS_SINCE_LAST_EXECUTION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.case.required,
          RuleValidators.case.nonNegativeNumber,
        ]),
      ],
    },
    [CASE_ATTRIBUTE.CALENDAR_DAYS_SINCE_LAST_NOTIFICATION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.case.required,
          RuleValidators.case.nonNegativeNumber,
        ]),
      ],
    },
    [CASE_ATTRIBUTE.WORKING_DAYS_SINCE_LAST_NOTIFICATION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.case.required,
          RuleValidators.case.nonNegativeNumber,
        ]),
      ],
    },
    [CASE_ATTRIBUTE.LEVEL]: {
      inputs: [
        operatorSelect(),
        defaultInput(
          RuleConditionInputTypes.SELECT,
          [RuleValidators.case.required],
          dunningLevels.map(dl => ({
            key: dl.id,
            label: dl.name.length ? dl.name : i18n.t('dialog.ruleset.init-level'),
          })),
        ),
      ],
    },
    [CASE_ATTRIBUTE.CALENDAR_DAYS_UNTIL_CONTRACT_ENDS]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.case.required,
          RuleValidators.case.nonNegativeNumber,
        ]),
      ],
    },
    [CASE_ATTRIBUTE.COMMUNICATION_PROFILE]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(
          RuleConditionInputTypes.SELECT,
          [RuleValidators.case.required],
          getCommunicationProfiles().map(profile => ({
            key: `${profile.id}`,
            label: profile.name,
          })),
        ),
      ],
    },
    [CASE_ATTRIBUTE.CONTRACT_TYPE]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(
          RuleConditionInputTypes.MULTI_SELECT,
          [RuleValidators.case.required],
          Object.values(CONTRACT_TYPE).map(value => ({ key: value, label: i18n.t(`select.${value}`) })),
        ),
      ],
    },
    [CASE_ATTRIBUTE.UNASSIGNED_PAYMENTS_AMOUNT]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.CURRENCY, [RuleValidators.case.required, RuleValidators.validCurrency]),
      ],
    },
    [CASE_ATTRIBUTE.WORKING_DAYS_UNTIL_CONTRACT_ENDS]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.case.required,
          RuleValidators.case.nonNegativeNumber,
        ]),
      ],
    },
    [CASE_ATTRIBUTE.ADDITIONAL_VALUES]: {
      inputs: [
        {
          type: RuleConditionInputTypes.STRING,
          setter: (obj: CaseConditionForReact, val: RuleDefinitionValue) => (obj.attribExtension = val.toValue()),
          getter: (obj: CaseConditionForReact) => new RuleDefinitionStringValue(obj.attribExtension),
          validators: [RuleValidators.case.required, RuleValidators.case.sanitized],
        },
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(RuleConditionInputTypes.STRING, [RuleValidators.case.required, RuleValidators.case.sanitized]),
      ],
    },
    [CASE_ATTRIBUTE.TERMINATION]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(
          RuleConditionInputTypes.SELECT,
          [RuleValidators.case.required],
          [
            {
              key: 'true',
              label: i18n.t('select.terminated'),
            },
            {
              key: 'false',
              label: i18n.t('select.not-terminated'),
            },
          ],
        ),
      ],
    },
    [CASE_ATTRIBUTE.BALANCE_CONTRACT_AMOUNT]: {
      inputs: [
        operatorSelect(),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [RuleValidators.case.required, RuleValidators.negativeValidCurrency],
          undefined,
          availableComputedPropertiesFactory(license),
          true,
        ),
      ],
    },
    [CASE_ATTRIBUTE.PRODUCT_CODE]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(RuleConditionInputTypes.STRING, [RuleValidators.case.required, RuleValidators.case.sanitized]),
      ],
    },
    [CASE_ATTRIBUTE.CREDIT_SCORE]: {
      inputs: [
        operatorSelect([OPERATOR.EQ, OPERATOR.NEQ]),
        defaultInput(RuleConditionInputTypes.STRING, [RuleValidators.case.required, RuleValidators.case.sanitized]),
      ],
    },
    [CASE_ATTRIBUTE.FORECAST_ANNUAL_CONSUMPTION]: {
      inputs: [
        operatorSelect(),
        defaultInput(RuleConditionInputTypes.CURRENCY, [RuleValidators.case.required, RuleValidators.validCurrency]),
      ],
    },
  };
};
