import { RuleDefinitionValue } from './RuleInputs/RuleDefinitionBaseProps';
import { Condition } from '../../../../../interfaces/Interfaces';
import { Selectable } from '../../../../DunningSelectionPage/DunningSelection/input/InputInterfaces';
import { Validator } from '../../../../DunningSelectionPage/DunningSelection/input/Validators';

export enum RuleConditionInputTypes {
  STRING,
  INTEGER,
  CURRENCY,
  BOOLEAN,
  NO_INPUT,
  SELECT,
  MULTI_SELECT,
  OPERATOR,
}

export interface RuleConditionDefinition {
  validators?: Array<Validator<Condition>>;
  inputs: RuleConditionInputDefinition[];
}

export interface RuleConditionInputDefinition<C = any> {
  type: RuleConditionInputTypes;
  label?: string;
  validators?: Array<Validator<RuleDefinitionValue>>;
  getter: (obj: C) => RuleDefinitionValue;
  setter: (obj: C, val: RuleDefinitionValue) => void;
  selectables?: Selectable[];
  computedProperties?: Array<{ key: any; label: string }>;
  visible?: (obj: C, isEditable: boolean) => boolean;
  inputProps?: {};
  removeParameterPanel?: boolean;
  allowNegativeValues?: boolean;
}
