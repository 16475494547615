import React, { ReactNode, useMemo, useState } from 'react';

import { Param } from '../../../../../../interfaces/Interfaces';
import { ValidationResult } from '../../../../../DunningSelectionPage/DunningSelection/input/Validators';
import { RuleConditionDefinition, RuleConditionInputDefinition, RuleConditionInputTypes } from '../RuleConditionTypes';
import { RuleDefinitionBaseProps, RuleDefinitionParamValue } from '../RuleInputs/RuleDefinitionBaseProps';
import RuleDefinitionBooleanInput from '../RuleInputs/RuleDefinitionBooleanInput';
import RuleDefinitionCurrencyInput, { RuleDefinitionIntegerProps } from '../RuleInputs/RuleDefinitionCurrencyInput';
import RuleDefinitionIntegerInput from '../RuleInputs/RuleDefinitionIntegerInput';
import RuleDefinitionMultiSelectInput from '../RuleInputs/RuleDefinitionMultiSelectInput';
import RuleDefinitionNoInput from '../RuleInputs/RuleDefinitionNoInput';
import RuleDefinitionOperatorSelect from '../RuleInputs/RuleDefinitionOperatorSelect';
import RuleDefinitionSelectInput from '../RuleInputs/RuleDefinitionSelectInput';
import RuleDefinitionStringInput from '../RuleInputs/RuleDefinitionStringInput';

import './RuleConditionInputs.style.sass';

export interface RuleConditionProps<T extends { reactId: string }> {
  editable?: boolean;
  condition: T;
  onChange: (condition: T, validation: Record<any, ValidationResult[] | undefined>) => void;
  isEditable?: boolean;
  availableParameters: Param[];
  definition: RuleConditionDefinition;
}

export function RuleConditionInputs<T extends { reactId: string }>({
  condition,
  onChange,
  isEditable = true,
  availableParameters,
  definition,
}: RuleConditionProps<T>) {
  const [validations, setValidations] = useState<any>();

  function createInputProps(
    obj: T,
    def: RuleConditionInputDefinition<T>,
    idx: number,
  ): RuleDefinitionBaseProps & {
    key: string;
  } {
    return {
      label: def.label,
      value: def.getter(obj),
      onChange: (v: any, validationResults?: ValidationResult[]) => {
        def.setter(obj, v);
        const newValidation = { ...validations, [idx]: validationResults };
        setValidations((old: any) => ({ ...old, ...newValidation }));
        onChange(obj, newValidation);
      },
      isEditable,
      availableVariables: useMemo(
        () =>
          (def.computedProperties ?? []).concat(
            availableParameters.map(param => ({
              label: param.name,
              key: new RuleDefinitionParamValue(param.name),
              value: param.value,
            })),
          ),
        [def, availableParameters],
      ),
      inputProps: useMemo(() => ({ ...def.inputProps, selectables: def.selectables }), [def]),
      validators: def.validators,
      key: `${def.type}${idx}`,
    };
  }

  const inputTypeRenderer: {
    [key in RuleConditionInputTypes]: (obj: any, def: RuleConditionInputDefinition<T>, idx: number) => ReactNode;
  } = {
    [RuleConditionInputTypes.INTEGER]: (obj, def, idx) => (
      <RuleDefinitionIntegerInput {...createInputProps(obj, def, idx)} />
    ),
    [RuleConditionInputTypes.CURRENCY]: (obj, def, idx) => {
      const inputProps: RuleDefinitionIntegerProps = createInputProps(obj, def, idx);
      inputProps.removeParameterPanel = def.removeParameterPanel;
      inputProps.allowNegativeValues = def.allowNegativeValues;
      return <RuleDefinitionCurrencyInput {...inputProps} />;
    },
    [RuleConditionInputTypes.BOOLEAN]: (obj, def, idx) => (
      <RuleDefinitionBooleanInput {...createInputProps(obj, def, idx)} />
    ),
    [RuleConditionInputTypes.STRING]: (obj, def, idx) => (
      <RuleDefinitionStringInput {...createInputProps(obj, def, idx)} />
    ),
    [RuleConditionInputTypes.SELECT]: (obj, def, idx) => (
      <RuleDefinitionSelectInput {...createInputProps(obj, def, idx)} />
    ),
    [RuleConditionInputTypes.MULTI_SELECT]: (obj, def, idx) => (
      <RuleDefinitionMultiSelectInput {...createInputProps(obj, def, idx)} />
    ),
    [RuleConditionInputTypes.NO_INPUT]: (obj, def, idx) => (
      <RuleDefinitionNoInput {...createInputProps(obj, def, idx)} />
    ),
    [RuleConditionInputTypes.OPERATOR]: (obj, def, idx) => (
      <RuleDefinitionOperatorSelect {...createInputProps(obj, def, idx)} />
    ),
  };

  function isInputVisible(input: RuleConditionInputDefinition): boolean {
    if (input.visible?.(condition, isEditable)) return true;
    return !input.visible;
  }

  return (
    <>
      {definition?.inputs.filter(isInputVisible).map((input, idx) => {
        return inputTypeRenderer[input.type](condition, input, idx);
      })}
    </>
  );
}
