import React, { type FunctionComponent, useContext, useState } from 'react';

import { Card, Classes, Dialog, DialogBody, DialogFooter, Elevation, InputGroup, Spinner } from '@blueprintjs/core';

import { UserContext } from '../../App';
import { RIGHTS } from '../../interfaces/Interfaces';
import { Button } from '../../lib/Button';
import { deleteExecutions } from '../../services/ApiService';

import { alertToast, showSuccessToast, showWarningToast } from '../Toast/AlertToast';

const ResetExecutions: FunctionComponent = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const [contractIdsInput, setContractIdsInput] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const userContext = useContext(UserContext);
  const hasExecutionsResetRights = userContext.rights.includes(RIGHTS.EXECUTIONS_RESET);

  if (!hasExecutionsResetRights) {
    // User does not have the required rights; do not render the component
    return null;
  }

  const openDialog = (deleteAll: boolean) => {
    setIsDeleteAll(deleteAll);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    if (!isProcessing) {
      setIsDialogOpen(false);
    }
  };

  const handleConfirm = async () => {
    setIsProcessing(true);
    try {
      if (isDeleteAll) {
        await deleteExecutions();
      } else {
        const contractIds = contractIdsInput
          .split(',')
          .map(id => id.trim())
          .filter(id => id !== '')
          .map(id => Number(id));

        if (contractIds.length === 0 || contractIds.some(Number.isNaN)) {
          await showWarningToast('Please enter valid contract IDs!');
          setIsProcessing(false);
          return;
        }

        await deleteExecutions(contractIds);
      }
      await showSuccessToast('Executions deleted successfully');
      setContractIdsInput('');
    } catch (error: any) {
      await alertToast(error);
    } finally {
      setIsProcessing(false);
      setIsDialogOpen(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Remove any character that is not a digit or comma
    const validatedValue = value.replace(/[^\d,]/g, '');
    setContractIdsInput(validatedValue);
  };

  const isDeleteSpecificExecutionsButtonDisabled = contractIdsInput.trim() === '';

  return (
    <Card className="card" interactive={false} elevation={Elevation.TWO}>
      <h5 className="bp5-heading">Reset Executions</h5>
      <div className="row">
        <Button
          id="delete-all-executions-button"
          testId="delete-all-executions-button"
          type="primary"
          className="button"
          disabled={isProcessing}
          onClick={() => openDialog(true)}>
          Delete All Executions
        </Button>
      </div>
      <div className="row">
        <InputGroup
          placeholder="Contract IDs"
          value={contractIdsInput}
          onChange={handleInputChange}
          style={{ width: '300px' }}
          disabled={isProcessing}
        />
        <Button
          id="delete-specific-executions-button"
          testId="delete-specific-executions-button"
          type="primary"
          className="button"
          disabled={isProcessing || isDeleteSpecificExecutionsButtonDisabled}
          onClick={() => openDialog(false)}>
          Delete Executions for Contract IDs
        </Button>
      </div>

      <Dialog isOpen={isDialogOpen} onClose={closeDialog} title="Confirm Deletion">
        <DialogBody>
          {isProcessing && (
            <div className="loading-spinner">
              <Spinner />
            </div>
          )}
          {isDeleteAll ? (
            <p>
              Are you sure you want to delete <strong>all executions</strong>?
            </p>
          ) : (
            <p>
              Are you sure you want to delete executions for the following contract IDs?
              <br />
              <strong>{contractIdsInput}</strong>
            </p>
          )}
        </DialogBody>
        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              id="cancel-button"
              testId="cancel-button"
              type="secondary"
              className="button"
              disabled={isProcessing}
              onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              id="confirm-delete-button"
              testId="confirm-delete-button"
              type="primary"
              className="button"
              disabled={isProcessing}
              onClick={handleConfirm}>
              Delete
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </Card>
  );
};

export default ResetExecutions;
