import { type ReactNode } from 'react';

import { Callout, Intent, OverlayToaster, type ToastProps } from '@blueprintjs/core';
import { Error } from '@blueprintjs/icons';

import { type ErrorMessage } from 'interfaces/enums';

import {
  ErrorResponse,
  ErrorResponseFromJSON,
  FetchError,
  RequiredError,
  ResponseError,
} from '../../generated-sources/openapi';
import i18n from '../../i18n/i18n';

import './Toast.style.sass';

export async function alertToast(error: Error) {
  if (error instanceof RequiredError || error instanceof FetchError) {
    showToast(createDefaultMessage(error.message));
  }

  if (error instanceof ResponseError) {
    const errorMessage: string = await error.response.json();
    const errorResponse = ErrorResponseFromJSON(errorMessage);

    showToast(createMessage(errorResponse));
  }
}

export async function alertToastFromErrorResponse(errorResponse: ErrorResponse) {
  showToast(createMessage(errorResponse));
}

function showToast(toastMessage: ReactNode) {
  const toastProps: ToastProps = {
    message: toastMessage,
    timeout: -1,
    intent: 'danger',
  };
  OverlayToaster.create().show(toastProps);
}

function createDefaultMessage(errorString: string | undefined) {
  return (
    <>
      <h3> An unknown error occurred</h3>
      <br />
      <Callout intent="danger" icon={<Error />}>
        {errorString !== undefined && errorString}
      </Callout>
    </>
  );
}
function createMessage(errorMessage: ErrorMessage | ErrorResponse): ReactNode {
  if (!errorMessage.errorType) {
    return createDefaultMessage(errorMessage.toString());
  }
  return (
    <>
      <h3> An Error occurred while fetching data from the server</h3>
      <div>Status: {errorMessage.status}</div>
      <div>Code: {errorMessage.title}</div>
      <div>Type: {errorMessage.errorType}</div>
      <div>Message: {errorMessage.detail}</div>
      <div>TraceId: {errorMessage.traceId}</div>

      {errorMessage.additionalInformation !== undefined && <br />}
      {errorMessage.additionalInformation !== undefined && <div>Additional Information</div>}
      {errorMessage.additionalInformation?.map((info, index) => (
        <li className="item" key={`${info.reason}_${index}`}>
          {i18n.t(info.reason)}
        </li>
      ))}
      <br />
      <div>Please contact technical support and provide the above error status, code, traceId and error message.</div>
      <br />
    </>
  );
}

export async function showSuccessToast(toastMessage: string) {
  const toastProps: ToastProps = {
    message: toastMessage,
    timeout: -1,
    intent: Intent.SUCCESS,
  };
  OverlayToaster.create().show(toastProps);
}

export async function showWarningToast(toastMessage: string) {
  const toastProps: ToastProps = {
    message: toastMessage,
    timeout: -1,
    intent: Intent.WARNING,
  };
  OverlayToaster.create().show(toastProps);
}
