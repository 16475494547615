import { useContext } from 'react';

import { defaultInput, operatorSelect } from './RuleConditionHelper';
import { LicenseContext } from '../../../../../App';
import { useOperatorsForAggregConditionAndLicense } from '../../../../../hooks/LicenseHooks';
import i18n from '../../../../../i18n/i18n';
import { AGGREG_FUNCTION, ITEM_ATTRIBUTE } from '../../../../../interfaces/enums';
import { AggregCondition, DunningLevelForReact } from '../../../../../interfaces/Interfaces';
import { availableComputedPropertiesFactory } from '../../../../../util/Util';
import { RuleValidators } from '../../Validation/RuleValidators';
import { RuleConditionDefinition, RuleConditionInputTypes } from '../RuleCondition/RuleConditionTypes';
import { RuleDefinitionStringValue } from '../RuleCondition/RuleInputs/RuleDefinitionBaseProps';

const aggregatedFunctionInputFactory = () => ({
  type: RuleConditionInputTypes.SELECT,
  getter: (obj: AggregCondition) => new RuleDefinitionStringValue(obj.aggregFunction),
  setter: (obj: AggregCondition, val: any) => (obj.aggregFunction = val.toValue()),
  selectables: Object.keys(AGGREG_FUNCTION).map(func => ({
    key: func,
    label: i18n.t(`select.${func}`),
  })),
  validators: [RuleValidators.aggregatedThis.required],
});

export const aggregatedRuleConditionDefinitionFactory: (dunningLevels: DunningLevelForReact[]) => {
  [key in ITEM_ATTRIBUTE]: RuleConditionDefinition;
} = dunningLevels => {
  const operators = useOperatorsForAggregConditionAndLicense();
  const license = useContext(LicenseContext);
  return {
    [ITEM_ATTRIBUTE.LEVEL]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.LEVEL]),
        defaultInput(
          RuleConditionInputTypes.INTEGER,
          [RuleValidators.aggregatedThis.required],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.GROSS_AMOUNT]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.GROSS_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.validCurrency,
          ],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OPEN_AMOUNT]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.OPEN_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.validCurrency,
          ],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.SETTLED_AMOUNT]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.SETTLED_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.validCurrency,
          ],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS]),
        defaultInput(
          RuleConditionInputTypes.INTEGER,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.aggregatedThis.nonNegativeNumber,
          ],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]: {
      inputs: [
        aggregatedFunctionInputFactory(),
        operatorSelect(operators[ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]),
        defaultInput(
          RuleConditionInputTypes.INTEGER,
          [
            RuleValidators.aggregatedThis.required,
            RuleValidators.aggregatedThis.sanitized,
            RuleValidators.aggregatedThis.nonNegativeNumber,
          ],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
  };
};
