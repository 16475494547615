import React, { type FunctionComponent, useContext } from 'react';

import i18n from 'i18n/i18n';
import {
  CaseConditionForReact,
  CustomerCaseConditionForReact,
  DunningLevelForReact,
  type ParamForReact,
} from 'interfaces/Interfaces';

import { LicenseContext } from '../../../../App';
import { useAttributesForLicense } from '../../../../hooks/LicenseHooks';
import { RuleSetAssignmentCriteriaForReact } from '../../../../interfaces/DynamicCriteriaInterfaces';
import { LICENSE_FEATURE_PACK } from '../../../../license/AppLicense';
import { ValidationResult } from '../../../DunningSelectionPage/DunningSelection/input/Validators';
import RuleConditionGroup from '../../Rule/RuleDefinition/RuleCondition/RuleConditionGroup/RuleConditionGroup';
import RuleConditions from '../../Rule/RuleDefinition/RuleCondition/RuleConditions/RuleConditions';
import { caseRuleConditionDefinitionFactory } from '../../Rule/RuleDefinition/RuleConditionDefinitions/CaseConditionDefinitions';
import { customerRuleConditionDefinitionFactory } from '../../Rule/RuleDefinition/RuleConditionDefinitions/CustomerConditionDefinitions';

interface DynamicCriteriaProps {
  params: ParamForReact[];
  ruleSetAssignmentCriteria: RuleSetAssignmentCriteriaForReact;
  dunningLevels: DunningLevelForReact[];
  isParamAndLevelEditable: boolean;
  onCaseConditionsChange: (
    newConditions: any,
    validations: Record<string, Record<string, ValidationResult[] | undefined>>,
  ) => void;
  onCustomerCaseConditionsChange: (
    newConditions: any,
    validations: Record<string, Record<string, ValidationResult[] | undefined>>,
  ) => void;
}

const RuleSetDynamicCriteria: FunctionComponent<DynamicCriteriaProps> = (props: DynamicCriteriaProps) => {
  const conditionTypes = useAttributesForLicense(
    LICENSE_FEATURE_PACK.DYNAMIC_CUSTOMER_SELECTION_PACK,
    'CUSTOMER_ATTRIBUTE',
  );
  const caseConditionTypes = useAttributesForLicense(
    LICENSE_FEATURE_PACK.DYNAMIC_CONTRACT_SELECTION_PACK,
    'CASE_ATTRIBUTE',
  );
  const license = useContext(LicenseContext);

  return (
    <>
      <div className="dialog-title-2">{i18n.t('dialog.ruleset.ruleset-criteria')}</div>
      {props.isParamAndLevelEditable && (
        <div className="padding-bottom">{i18n.t('dialog.ruleset.criteria-description')}</div>
      )}
      <RuleConditionGroup title={i18n.t('dialog.rule.when-customer-case-title')}>
        <RuleConditions
          conditionTypes={conditionTypes.map(type => ({
            type,
            name: i18n.t(`select.${type}`),
          }))}
          conditions={props.ruleSetAssignmentCriteria.customerCaseConditions}
          availableParameters={props.params}
          definitions={customerRuleConditionDefinitionFactory()}
          isEditable={props.isParamAndLevelEditable}
          newConditionFactory={() => new CustomerCaseConditionForReact()}
          onChange={props.onCustomerCaseConditionsChange}
          typeGetter={object => object.attrib}
          typeSetter={(object, type) => (object.attrib = type)}
          section="dynamic-criteria-customer-group"
        />
      </RuleConditionGroup>

      <RuleConditionGroup title={i18n.t('dialog.rule.when-case-title')}>
        <RuleConditions
          conditionTypes={caseConditionTypes.map(type => ({
            type,
            name: i18n.t(`select.${type}`),
          }))}
          conditions={props.ruleSetAssignmentCriteria.caseConditions}
          availableParameters={props.params}
          definitions={caseRuleConditionDefinitionFactory(props.dunningLevels, license)}
          isEditable={props.isParamAndLevelEditable}
          newConditionFactory={() => new CaseConditionForReact()}
          onChange={props.onCaseConditionsChange}
          typeGetter={object => object.attrib}
          typeSetter={(object, type) => (object.attrib = type)}
          section="dynamic-criteria-contract-group"
        />
      </RuleConditionGroup>
    </>
  );
};
export default RuleSetDynamicCriteria;
