import { useContext } from 'react';

import { defaultInput, operatorSelect } from './RuleConditionHelper';
import { LicenseContext } from '../../../../../App';
import { useOperatorsForGroupSelectorAndLicense } from '../../../../../hooks/LicenseHooks';
import i18n from '../../../../../i18n/i18n';
import { ITEM_ATTRIBUTE } from '../../../../../interfaces/enums';
import { DunningLevelForReact } from '../../../../../interfaces/Interfaces';
import { availableComputedPropertiesFactory } from '../../../../../util/Util';
import { RuleValidators } from '../../Validation/RuleValidators';
import { RuleConditionDefinition, RuleConditionInputTypes } from '../RuleCondition/RuleConditionTypes';

export const itemRuleConditionDefinitionFactory: (dunningLevels: DunningLevelForReact[]) => {
  [key in ITEM_ATTRIBUTE]: RuleConditionDefinition;
} = dunningLevels => {
  const operators = useOperatorsForGroupSelectorAndLicense();
  const license = useContext(LicenseContext);
  return {
    [ITEM_ATTRIBUTE.LEVEL]: {
      inputs: [
        operatorSelect(operators[ITEM_ATTRIBUTE.LEVEL]),
        defaultInput(
          RuleConditionInputTypes.SELECT,
          [RuleValidators.group.required],
          dunningLevels.map(dl => ({
            key: dl.id,
            label: dl.name.length ? dl.name : i18n.t('dialog.ruleset.init-level'),
          })),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.GROSS_AMOUNT]: {
      inputs: [
        operatorSelect(operators[ITEM_ATTRIBUTE.GROSS_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [RuleValidators.group.required, RuleValidators.validCurrency],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OPEN_AMOUNT]: {
      inputs: [
        operatorSelect(operators[ITEM_ATTRIBUTE.OPEN_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [RuleValidators.group.required, RuleValidators.validCurrency],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.SETTLED_AMOUNT]: {
      inputs: [
        operatorSelect(operators[ITEM_ATTRIBUTE.SETTLED_AMOUNT]),
        defaultInput(
          RuleConditionInputTypes.CURRENCY,
          [RuleValidators.group.required, RuleValidators.validCurrency],
          undefined,
          availableComputedPropertiesFactory(license),
        ),
      ],
    },
    [ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS]: {
      inputs: [
        operatorSelect(operators[ITEM_ATTRIBUTE.OVERDUE_CALENDAR_DAYS]),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.group.required,
          RuleValidators.group.nonNegativeNumber,
        ]),
      ],
    },
    [ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]: {
      inputs: [
        operatorSelect(operators[ITEM_ATTRIBUTE.OVERDUE_WORKING_DAYS]),
        defaultInput(RuleConditionInputTypes.INTEGER, [
          RuleValidators.group.required,
          RuleValidators.group.nonNegativeNumber,
        ]),
      ],
    },
  };
};
